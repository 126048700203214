import { TabContext, TabList, TabPanel } from "@mui/lab"
import { Box, Paper, Stack, Tab } from "@mui/material"
import { createContext, useContext, useEffect, useState } from "react";
import OrgContainer from "../Organisation/OrgContainer";
import { postDciApi } from "../../utils/callDciApi";
import { Background, Panel, ReactFlow, type Node, type Edge, useReactFlow, ReactFlowProvider, useNodesState, useEdgesState, OnNodesChange, NodeChange, applyNodeChanges, FitView } from "@xyflow/react";
import Dagre from '@dagrejs/dagre';
import '@xyflow/react/dist/style.css';
import { FieldDefinitionNode, FieldDefinitionNodeData } from "./FieldDefinitionNode";
import { createStore, StoreApi, useStore } from "zustand";
import authClient from "../Auth/auth-client";
import { SetterFunction } from "../Zustand/Shared";

const MapStoreContext = createContext<StoreApi<MapStore>>(null as any as StoreApi<MapStore>);

const useMapStore = <TProp,>(selector: (store: MapStore) => TProp) => {
    const context = useContext(MapStoreContext);
    return useStore(context, selector);
}

const initialise = async (mapId: number, set: SetterFunction<MapStore>, get: () => MapStore) => {
    set({
        isLoading: true,
        isLoadingError: false,
        initialised: true
    });

    try {
        const token = await authClient.getTokenSilently();
        const map = await fetchMap(token, mapId);
    
        const newNodes = map.mapNodes.map((m, i) => ({
            id: m.workItemAttributeMapNodeId.toString(),
            position: { x: 0, y: i * 100 },
            ...nodeTypeAndData(m)
        }));
    
        const newEdges = map.mapNodes
            .filter(f => f.parentWorkItemAttributeMapNodeId !== 0)
            .map<Edge>(m => ({
                id: `${m.parentWorkItemAttributeMapNodeId}-${m.workItemAttributeMapNodeId}`,
                source: m.parentWorkItemAttributeMapNodeId.toString(),
                target: m.workItemAttributeMapNodeId.toString()
            }));
    
        set({
            isLoading: false,
            description: map.description,
            edges: newEdges,
            nodes: newNodes,
            needsLayout: true
        });
    } catch (error) {
        set({ isLoading: false, isLoadingError: true });
        throw error;
    }
}

const performLayout = (set: SetterFunction<MapStore>) => {
    set(state => {
        const g = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));
        g.setGraph({ rankdir: 'TB' });
       
        state.edges.forEach((edge: Edge) => g.setEdge(edge.source, edge.target));
        state.nodes.forEach((node: Node) => {
            g.setNode(node.id, {
                ...node,
                width: node.measured?.width ?? 130,
                height: node.measured?.height ?? 20,
            })
        });
       
        Dagre.layout(g);
       
        return {
            nodes: state.nodes.map((node: Node) => {
                const position = g.node(node.id);

                // Shift the dagre node position (anchor=center center) to the top left
                // so it matches the React Flow node anchor point (top left).
                const x = position.x - (node.measured?.width ?? 0) / 2;
                const y = position.y - (node.measured?.height ?? 0) / 2;
        
                return { ...node, position: { x, y } };
            }),
            //edges,
        };
    });
};

interface MapStore {
    description: string | null,
    edges: Edge[],
    editMode: boolean,
    initialised: boolean,
    isLoading: boolean,
    isLoadingError: boolean,
    needsLayout: boolean,
    nodes: Node[],

    initialise: (mapId: number) => void,
    onNodesChange: OnNodesChange,
    performLayout: () => void,
    fitView: FitView
}

const createMapStore = (fitView: FitView) => createStore<MapStore>((set, get) => ({
    description: null,
    edges: [],
    editMode: true,
    initialised: false,
    isLoading: false,
    isLoadingError: false,
    needsLayout: false,
    nodes: [],

    initialise: (mapId: number) => initialise(mapId, set, get),
    onNodesChange: changes => {
        set({
            nodes: applyNodeChanges(changes, get().nodes)
        });

        const state = get();
        if (state.needsLayout) {
            state.performLayout();
            setTimeout(() => fitView(), 20);
        }
    },
    performLayout: () => performLayout(set),
    fitView
}));

interface WorkItemAttributeMapViewProps {
    id: number
}

interface WorkItemAttributeMap {
    workItemAttributeMapId: number
    description: string
    enabled: boolean
    mapNodes: WorkItemAttributeMapNode[]
    mapViews: WorkItemAttributeMapView[]
}

interface WorkItemAttributeMapNode {
    workItemAttributeMapNodeId: number
    parentWorkItemAttributeMapNodeId: number
    tableName: string
    columnName: string
    columnAlias: string
    value: string
    condition: string
    workQueueId: number
    enabled: boolean
}

interface WorkItemAttributeMapView {
    workItemAttributeMapId: number
    workItemAttributeMapViewId: number
    view: string
}

const fetchMap = async (token: string, id: number): Promise<WorkItemAttributeMap> => {
    const response = await postDciApi(`{workItemAttributeMaps(where:{workItemAttributeMapId:{eq:${id}}}){nodes{workItemAttributeMapId description enabled mapNodes{workItemAttributeMapNodeId parentWorkItemAttributeMapNodeId tableName columnName columnAlias value condition workQueueId enabled}mapViews{workItemAttributeMapId workItemAttributeMapViewId view}}}}`, token);
    if (response.errors) {
        throw new Error(response.errors[0]);
    } else {
        return response.data.workItemAttributeMaps.nodes[0] as WorkItemAttributeMap;
    }
}

interface MapTabProps {
    mapId: number
}

const nodeTypeAndData = (node: WorkItemAttributeMapNode) => {
    return {
        type: node.columnAlias ? 'fieldDefinition' : 'default',
        data: node.columnAlias
            ? {
                isRoot: node.parentWorkItemAttributeMapNodeId === 0,
                tableName: node.tableName,
                columnName: node.columnName,
                columnAlias: node.columnAlias
            } as FieldDefinitionNodeData
            : { label: node.value}
    }
}

const nodeTypes = {
    fieldDefinition: FieldDefinitionNode
}

const MapTabInner = () => {
    const nodes = useMapStore(s => s.nodes);
    const edges = useMapStore(s => s.edges);
    const onNodesChange = useMapStore(s => s.onNodesChange);

    const isLoading = useMapStore(s => s.isLoading);
    const isLoadingError = useMapStore(s => s.isLoadingError);
    const description = useMapStore(s => s.description);

    return <ReactFlow
        nodeTypes={nodeTypes}
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        // onEdgesChange={onEdgesChange}
        fitView
    >
        <Background />
        <Panel >{ isLoading ? 'Loading...' : isLoadingError ? 'An error occurred': description ?? 'Map' }</Panel>
    </ReactFlow>
}

const StoreWrapper = ({ mapId }: MapTabProps) => {
    const { fitView } = useReactFlow();
    const [ store ] = useState(() => {
        const store = createMapStore(fitView);
        store.getState().initialise(mapId);
        return store;
    });

    return <MapStoreContext.Provider value={store}>
        <MapTabInner />
    </MapStoreContext.Provider>
}

const MapTab = ({ mapId }: MapTabProps) => {
    return <ReactFlowProvider>
        <StoreWrapper mapId={mapId} />
    </ReactFlowProvider>
}

const WorkItemAttributeMapView = ({ id }: WorkItemAttributeMapViewProps) => {
    const [ tabIndex, setTabIndex ] = useState('map');

    return <OrgContainer>
        <TabContext value={tabIndex}>
            <Stack component={Paper} sx={{ height:'100%' }}>
                <Box>
                    <TabList onChange={(_, v) => setTabIndex(v)}>
                        <Tab label='Map' value='map' />
                        <Tab label='Views' value='views' />
                    </TabList>
                </Box>
                <TabPanel sx={{ flexGrow:1, overflow:'hidden' }} value='map'>
                    <MapTab mapId={id} />
                </TabPanel>
            </Stack>
        </TabContext>
    </OrgContainer>
}

export { useMapStore, WorkItemAttributeMapView }