import { Autocomplete, Box, TextField } from '@mui/material';
import { Handle, Node, NodeProps, Position } from '@xyflow/react';
import { postDciApi } from '../../utils/callDciApi';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { uniq } from 'lodash';
import { useMapStore } from './WorkItemAttributeMapView';

interface Extract {
    extractId: number
    destinationSchemaName: string
}

interface ExtractTable {
    extractId: number
    extractTableId: number
    tableName: string
}

interface ExtractColumn {
    extractId: number
    extractTableId: number
    extractColumnId: number
    columnName: string
}

const fetchExtracts = async (token: string): Promise<Extract[]> => {
    const response = await postDciApi(`{extracts{nodes{extractId destinationSchemaName}}}`, token);
    if (response.errors) {
        throw new Error(response.errors[0]);
    } else {
        return response.data.extracts.nodes as Extract[];
    }
}

const useExtracts = () => {
    const { getAccessTokenSilently } = useAuth0();

    return useQuery({
        queryKey: ['extracts'],
        queryFn: async () => fetchExtracts(await getAccessTokenSilently())
    })
}

const fetchTables = async (token: string): Promise<ExtractTable[]> => {
    const response = await postDciApi(`{extractTables(order:{extractId:ASC,tableName:ASC}){nodes{tableName extractId extractTableId}}}`, token);
    if (response.errors) {
        throw new Error(response.errors[0]);
    } else {
        return response.data.extractTables.nodes as ExtractTable[];
    }
}

const useExtractTables = () => {
    const { getAccessTokenSilently } = useAuth0();

    return useQuery({
        queryKey: ['extractTables'],
        queryFn: async () => fetchTables(await getAccessTokenSilently())
    })
}

const fetchColumns = async (token: string): Promise<ExtractColumn[]> => {
    const response = await postDciApi(`{extractColumns(order:{extractId:ASC,extractTableId:ASC,columnName:ASC}){nodes{extractId extractTableId extractColumnId columnName}}}`, token);
    if (response.errors) {
        throw new Error(response.errors[0]);
    } else {
        return response.data.extractColumns.nodes as ExtractColumn[];
    }
}

const useExtractColumns = () => {
    const { getAccessTokenSilently } = useAuth0();

    return useQuery({
        queryKey: ['extractColumns'],
        queryFn: async () => fetchColumns(await getAccessTokenSilently())
    })
}

type FieldDefinitionNodeData = {
    extractId: number,
    isRoot: boolean,
    tableName: string | null,
    columnName: string | null,
    columnAlias: string | null
}

type FieldDefinitionNode = Node<FieldDefinitionNodeData, 'fieldDefinition'>

const FieldDefinitionNode = ({ data }: NodeProps<FieldDefinitionNode>) => {
    const { isFetching: isExtractsFetching, isError: isExtractsError, data: extracts } = useExtracts();
    const { isFetching: isTablesFetching, isError: isTablesError, data: extractTables } = useExtractTables();
    const { isFetching: isColumnsFetching, isError: isColumnsError, data: extractColumns } = useExtractColumns();

    const tableOptions = useMemo(() => {
        if (isExtractsFetching || isTablesFetching || isExtractsError || isTablesError || !extracts || !extractTables) {
            return [];
        }

        return uniq(extractTables.map(et => {
            const extract = extracts.find(e => e.extractId === et.extractId);
            return `${extract?.destinationSchemaName ?? 'MISSING'}_${et.tableName}`;
        }));
    }, [ isExtractsFetching, isTablesFetching, isExtractsError, isTablesError, extracts, extractTables ])

    const columnOptions = useMemo(() => {
        if (data.tableName === null || isExtractsFetching || isTablesFetching || isColumnsFetching || isExtractsError || isTablesError || isColumnsError || !extracts || !extractTables || !extractColumns) {
            return [];
        }

        const match = data.tableName.match(/^([A-Z][A-Z0-9]*)_([A-Z][A-Z0-9_]*)$/i);
        if (match === null || match.length !== 3) {
            return [];
        }

        const schemaName = match[1];
        const tableName = match[2];

        const extract = extracts.find(e => e.destinationSchemaName.toLowerCase() === schemaName.toLowerCase());
        if (!extract) {
            return [];
        }

        const table = extractTables.find(et => et.extractId === extract.extractId && et.tableName.toLowerCase() === tableName.toLowerCase());
        if (!table) {
            return [];
        }

        return extractColumns
            .filter(c => c.extractId === extract.extractId && c.extractTableId === table.extractTableId)
            .map(c => c.columnName);
    }, [ data.tableName, isExtractsFetching, isTablesFetching, isColumnsFetching, isExtractsError, isTablesError, isColumnsError, extracts, extractTables, extractColumns ])

    const editMode = useMapStore(s => s.editMode);

    return <>
        { data.isRoot ? null : <Handle type="target" position={Position.Top} /> }
        <Box sx={theme => ({
            padding: '12px 6px',
            backgroundColor: theme.palette.background.default,
            maxWidth:'200px',
            border: `1px ${theme.palette.divider} solid`,
            borderRadius:'5px'
        })}>
            <Autocomplete
                disabled={!editMode}
                freeSolo
                loading={isExtractsFetching || isTablesFetching || isExtractsError || isTablesError || !extracts || !extractTables}
                value={data.tableName}
                isOptionEqualToValue={(o, v) => o.toLowerCase() === v.toLowerCase()}
                options={tableOptions}
                componentsProps={{ popper: { style: { width: 'fit-content' } } }}
                renderInput={(params) => (
                    <TextField {...params} className='nodrag' fullWidth size='small' label='Table' margin='dense' />
                )}
            />
            <Autocomplete
                disabled={!editMode}
                freeSolo
                loading={isExtractsFetching || isTablesFetching || isColumnsFetching || isExtractsError || isTablesError || isColumnsError || !extracts || !extractTables || !extractColumns}
                value={data.columnName}
                isOptionEqualToValue={(o, v) => o.toLowerCase() === v.toLowerCase()}
                options={columnOptions}
                componentsProps={{ popper: { style: { width: 'fit-content' } } }}
                renderInput={(params) => (
                    <TextField {...params} className='nodrag' fullWidth size='small' label='Column' margin='dense' />
                )}
            />
            <TextField disabled={!editMode} className='nodrag' fullWidth size='small' label='Alias' value={data.columnAlias} margin='dense' />
        </Box>
        <Handle type="source" position={Position.Bottom} id="a" />
    </>
}

export { FieldDefinitionNode }
export type { FieldDefinitionNodeData }