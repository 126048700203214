import { useMemo } from "react"
import { Navigate, Route, Routes, useParams } from "react-router-dom"
import { useStore } from "zustand"
import { appGlobalStore } from "../../AppGlobalStore"
import { permissions } from "../../utils/dciConstants"
import dciPaths from "../../utils/dciPaths"
import userHasPermission from "../../utils/userHasPermission"
import { AuditItemView } from "../AuditItemView"
import { CoreRulesList } from "../Core/CoreRulesList"
import { Table } from "../Core/Table"
import { Tables } from "../Core/Tables"
import DashboardChooser from "../Dashboard/DashboardChooser"
import { ReportStatisticsAggregationList } from "../History/history"
import { ReportingRunHistoryView } from "../History/ReportingRunHistoryView"
import { EditOrganisation } from "../Organisation/EditOrganisation"
import { OrganisationView } from "../Organisation/OrganisationView"
import OrgConfig from "../Organisation/OrgConfig"
import OrgConstants from "../Organisation/OrgConstants"
import { OrgValueLists } from "../Organisation/OrgValueLists"
import Role from "../Organisation/Role"
import Roles from "../Organisation/Roles"
import Team from "../Organisation/Team"
import { Teams } from "../Organisation/Teams"
import User from "../Organisation/User"
import { UsersList } from "../Organisation/UsersList"
import WorkQueue from "../Organisation/WorkQueue"
import WorkQueues from "../Organisation/WorkQueues"
import { PermissionedRoute } from "../PermissionedRoute"
import { RuleGroupView } from "../Rules/RuleGroup"
import { RuleGroups } from "../Rules/RuleGroups"
import { Rules } from "../Rules/Rules"
import { RuleView } from "../Rules/RuleView"
import { Distribution, Queued, InProgress, AwaitingInformation, OverrideRequest, Referred, Corrected, Override } from "../WorkItems/Lists"
import { WorkItemView } from "../WorkItems/WorkItemView"
import { SegmentMap } from "./DciBreadcrumb"
import { TestPage } from "./TestPage"
import { UserActivityView } from "../User/UserActivityView"
import { CoreSchema } from "../Core/CoreSchema"
import { NewReporting } from "../Reporting/NewReporting"
import { WorkItemsReport } from "../Reporting/WorkItemsReport"
import CoreRoles from "../Core/CoreRoles"
import { CoreRoleView } from "../Core/CoreRole"
import { WorkItemAttributeMapsList } from "../Core/WorkItemAttributeMapList"
import { WorkItemAttributeMapView } from "../Core/WorkItemAttributeMapView"

const WithId = ({ component }: { component: (id: number) => JSX.Element }) => {
    const params = useParams();
    const id = parseInt(params.id ?? '-1');
    return component(id);
}

interface AllRoutesProps {
    setBreadcrumbs: React.Dispatch<React.SetStateAction<SegmentMap[]>>
}

const AllRoutes = ({ setBreadcrumbs }: AllRoutesProps) => {
    const currentUser = useStore(appGlobalStore, s => s.currentUser);
    const { idParam } = useParams();
    const id = useMemo(() => parseInt(idParam ?? '-1'), [ idParam ]);

    return (
        <Routes>
            <Route 
                path='/' 
                element={ userHasPermission(currentUser, permissions.VIEW_DASHBOARD) 
                    ? <Navigate to='/dashboard' />
                    : <Navigate to='/workitems/inprogress' />
                } 
            />

            <Route 
                path='/dashboard'
                element={
                    <PermissionedRoute requiredPermission={permissions.VIEW_DASHBOARD} alternativePath='/workitems/inprogress'>
                        <DashboardChooser />
                    </PermissionedRoute>
                }
            />

            <Route path='/dashboard' element={<div>dashboard</div>} />

            <Route path={dciPaths.workItemsDistribution.pattern} element={<WithId component={id => <Distribution />} />} />
                
            <Route path={dciPaths.workItemsQueued.pattern} element={<WithId component={id => <Queued />} />} />

            <Route path={dciPaths.workItemsInProgress.pattern} element={<WithId component={id => <InProgress />} />} />

            <Route path={dciPaths.workItemsAwaitingInformation.pattern} element={<WithId component={id => <AwaitingInformation />} />} />

            <Route path={dciPaths.workItemsOverrideRequest.pattern} element={<WithId component={id => <OverrideRequest />} />} />

            <Route path={dciPaths.workItemsReferred.pattern} element={<WithId component={id => <Referred />} />} />

            <Route path={dciPaths.workItemsCorrected.pattern} element={<WithId component={id => <Corrected />} />} />

            <Route path={dciPaths.workItemsOverride.pattern} element={<WithId component={id => <Override />} />} />

            <Route path={dciPaths.workItem.pattern} element={<WithId component={id => <WorkItemView id={id} setBreadcrumbs={setBreadcrumbs} />} />} />

            <Route path='/reporting' element={<NewReporting />} />

            <Route path={dciPaths.userActivity.pattern} element={<UserActivityView />} />

            {/* Configuration */}

            <Route path={dciPaths.configuration.pattern} element={ <Navigate to={dciPaths.organisation.buildLink()} />} />

            <Route path={dciPaths.organisation.pattern} element={<WithId component={id => <OrganisationView />} />} />

            <Route path='/configuration/organisation/edit' element={<WithId component={id => <EditOrganisation />} />} />

            <Route path={dciPaths.users.pattern} element={<UsersList />} />
            
            <Route path={dciPaths.user.pattern} element={<WithId component={id => <User id={id} setBreadcrumbs={setBreadcrumbs} />} />} />
            
            <Route path={dciPaths.teams.pattern} element={<Teams />} />
            
            <Route path={dciPaths.team.pattern} element={<WithId component={id => <Team id={id} setBreadcrumbs={setBreadcrumbs} />} />} />
            
            <Route path={dciPaths.roles.pattern} element={<Roles />} />

            <Route path={dciPaths.role.pattern} element={<WithId component={id => <Role id={id} setBreadcrumbs={setBreadcrumbs} />} />} />
            
            <Route path={dciPaths.workQueues.pattern} element={<WorkQueues />} />

            <Route path={dciPaths.workQueue.pattern} element={<WithId component={id => <WorkQueue id={id} setBreadcrumbs={setBreadcrumbs} />} />} />
            
            <Route path={dciPaths.rules.pattern} element={<Rules />} />

            <Route path={dciPaths.settings.pattern} element={<OrgConfig />} />

            <Route path={dciPaths.orgConstants.pattern} element={<WithId component={id => <OrgConstants />} />} />

            <Route path={dciPaths.orgValueLists.pattern} element={<WithId component={id => <OrgValueLists />} />} />

            <Route path={dciPaths.rule.pattern} element={<WithId component={id => <RuleView id={id} />} />} />
            
            <Route path={dciPaths.ruleGroups.pattern} element={<RuleGroups />} />

            <Route path={dciPaths.ruleGroup.pattern} element={<WithId component={id => <RuleGroupView id={id} setBreadcrumbs={setBreadcrumbs} />} />} />
            
            <Route path={dciPaths.audit.pattern} element={<WithId component={id => <AuditItemView id={id} />} />} />

            <Route path={dciPaths.history.pattern} element={<ReportStatisticsAggregationList />} />

            <Route path={dciPaths.reportingRun.pattern} element={<WithId component={id => <ReportingRunHistoryView id={id} />} />} />

            <Route path='/test' element={<WithId component={id => <TestPage />} />} />

            {/* Routes with no menu navigation */}
            <Route path='/workitems' element={<WorkItemsReport />} />

            {/* Core */}

            <Route path={dciPaths.coreTables.pattern} element={<PermissionedRoute requiredPermission={permissions.VIEW_EXTRACT_TABLES} alternativePath='/'><Tables /></PermissionedRoute>} />
                
            <Route path={dciPaths.coreRulesList.pattern} element={<PermissionedRoute requiredPermission={permissions.VIEW_CORE_REPORT_TESTS} alternativePath='/'><CoreRulesList /></PermissionedRoute>} />

            <Route path={dciPaths.coreTable.pattern} element={<PermissionedRoute requiredPermission={permissions.VIEW_EXTRACT_TABLES} alternativePath='/'><Table extractTableId={id} setBreadcrumbs={setBreadcrumbs} /></PermissionedRoute>} />

            <Route path={dciPaths.coreSchema.pattern} element={<PermissionedRoute requiredPermission={permissions.VIEW_ORGANISATION_SCHEMAS} alternativePath='/'><CoreSchema /></PermissionedRoute>} />

            <Route path={dciPaths.coreRoles.pattern} element={<PermissionedRoute requiredPermission={permissions.VIEW_CORE_ROLES} alternativePath='/'><CoreRoles /></PermissionedRoute>} />

            <Route path={dciPaths.coreRole.pattern} element={<PermissionedRoute requiredPermission={permissions.VIEW_CORE_ROLES} alternativePath='/'><WithId component={id => <CoreRoleView id={id} setBreadcrumbs={setBreadcrumbs} />} /></PermissionedRoute>} />

            <Route path={dciPaths.workItemAttributeMaps.pattern} element={<PermissionedRoute requiredPermission={permissions.VIEW_WORK_ITEM_ATTRIBUTE_MAPS} alternativePath='/'><WorkItemAttributeMapsList /></PermissionedRoute>} />

            <Route path={dciPaths.workItemAttributeMap.pattern} element={<PermissionedRoute requiredPermission={permissions.VIEW_WORK_ITEM_ATTRIBUTE_MAPS} alternativePath='/'><WithId component={id => <WorkItemAttributeMapView id={id} />} /></PermissionedRoute>} />
        </Routes>
    )
}

export { AllRoutes }