import React, { useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { permissions } from '../../utils/dciConstants';
import { isEqual, sortBy } from 'lodash';
import { 
    Box,
    Card,
    CardContent,
    Checkbox,
    Button,
    Table as MuiTable,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
    Stack,
    alpha,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import {
    AddBox,
    Delete,
    Edit,
    Save
} from '@mui/icons-material';
import { CancellablePromise, callDciApiCancellable, postDciApi, postDciApiNoThrow } from '../../utils/callDciApi';
import userHasPermission from '../../utils/userHasPermission';
import { SegmentMap } from '../Layout/DciBreadcrumb';
import dciPaths from '../../utils/dciPaths';
import { CheckboxField } from '../DciControls';
import { useStore } from 'zustand';
import { appGlobalStore } from '../../AppGlobalStore';
import OrgContainer from '../Organisation/OrgContainer';
import authClient from '../Auth/auth-client';
import { OptionsObject, SnackbarKey, SnackbarMessage, useSnackbar } from 'notistack';

interface ApiRole {
    coreRoleId: number,
    description: string,
    enabled: boolean,
    coreRolePermissions: ApiRolePermission[]
    coreUserRoles: ApiCoreUserRole[]
}

interface ApiRolePermission {
    permissionId: number
}

interface ApiCoreUserRole {
    coreUser: ApiCoreUser
}

interface ApiCoreUser {
    coreUserId: number
    firstName: string
    surname: string
}

type Permission = {
    permissionId: number,
    description: string,
    enabled: boolean
};

interface AddUserDialogProps {
    isOpen: boolean
    setIsOpen: (value: boolean) => void
    excludeUserIds: number[]
    apply: (users: ApiCoreUser[]) => void
}

const getCoreUsers = async (exclude: number[]) => {
    let excludeClause = '';
    if (exclude.length > 0) {
        excludeClause = `(where:{coreUserId:{nin:[${exclude.join()}]}})`;
    }

    const token = await authClient.getTokenSilently();
    const response = await postDciApi(`{coreUsers${excludeClause}{coreUserId firstName surname}}`, token);

    if (response.errors) {
        return []
    } else {
        return response.data.coreUsers as ApiCoreUser[];
    }
}

const AddUserDialog = ({ isOpen, setIsOpen, excludeUserIds, apply }: AddUserDialogProps) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const [ availableUsers, setAvailableUsers ] = useState<ApiCoreUser[]>([]);
    const [ selectedUsers, setSelectedUsers ] = useState<number[]>([]);

    useEffect(() => {
        if (!isOpen) {
            return;
        }

        const fetchUsers = async () => {
            const users = await getCoreUsers(excludeUserIds);
            setAvailableUsers(users);
        }

        fetchUsers();
    }, [ isOpen, excludeUserIds ]);

    const addUsersAndClose = () => {
        apply(availableUsers.filter(u => selectedUsers.includes(u.coreUserId)));
        setIsOpen(false);
    }

    const selectAll = (checked: boolean) => {
        if (checked) {
            setSelectedUsers(availableUsers.map(au => au.coreUserId));
        } else {
            setSelectedUsers([]);
        }
    }

    const onMemberCheck = (userId: number, checked: boolean) => {
        if (checked) {
            setSelectedUsers(existing => [ ...existing, userId ]);
        } else {
            setSelectedUsers(existing => existing.filter(u => u !== userId));
        }
    }

    return <Dialog sx={{ minHeight:'400px' }} open={isOpen} fullScreen={fullScreen} maxWidth={'sm'} fullWidth aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add Role Member</DialogTitle>
        <DialogContent>
            <DialogContentText>
                Select users to add to this role
            </DialogContentText>

            <Box border={1} style={{ minHeight:'300px', maxHeight:'600px', width:'100%', overflowY:'scroll' }}>
                <MuiTable size='small' stickyHeader={true}>
                    <TableHead>
                        <TableRow>
                            <TableCell padding='checkbox'>
                                <Checkbox color='secondary' checked={availableUsers.length > 0 && selectedUsers.length === availableUsers.length} onChange={event => selectAll(event.target.checked)} />
                            </TableCell>
                            <TableCell>Name</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {availableUsers.map(m =>
                            <TableRow key={m.coreUserId}>
                                <TableCell padding='checkbox'><Checkbox color='secondary' checked={selectedUsers.includes(m.coreUserId)} onChange={event => onMemberCheck(m.coreUserId, event.target.checked)} /></TableCell>
                                <TableCell colSpan={2}>{[m.firstName, m.surname].join(' ')}</TableCell>
                            </TableRow>)}
                    </TableBody>
                </MuiTable>
            </Box>
        </DialogContent>

        <DialogActions>
            <Button onClick={() => setIsOpen(false)}>Cancel</Button>
            <Button disabled={selectedUsers.length === 0} onClick={addUsersAndClose}>Add</Button>
        </DialogActions>
    </Dialog>
}

interface UsersListControlsProps {
    editMode: boolean
    selectedUsers: number[]
    currentMembers: number[]
    removeSelectedUsers: () => void
    applyNewMembers: (users: ApiCoreUser[]) => void
}

const UsersListControls = ({ editMode, selectedUsers, currentMembers, removeSelectedUsers, applyNewMembers }: UsersListControlsProps) => {
    const [ addUserDialogIsOpen, setAddUserDialogIsOpen ] = useState(false);

    return !editMode ? null :
        <>
            <Button
                sx={theme => ({ 
                    color:theme.palette.text.primary,
                    borderColor:theme.palette.divider,
                    '&:hover': {
                        borderColor: theme.palette.action.disabledBackground,
                        backgroundColor: alpha(theme.palette.text.primary, theme.palette.action.hoverOpacity)
                    }
                })}
                style={{ margin:'0px 4px' }}
                disabled={selectedUsers.length === 0}
                endIcon={<Delete />}
                variant='outlined'
                onClick={removeSelectedUsers}
                aria-label='remove member'>
                    Remove
            </Button>
            <Button
                sx={theme => ({ 
                    color:theme.palette.text.primary,
                    borderColor:theme.palette.divider,
                    '&:hover': {
                        borderColor: theme.palette.action.disabledBackground,
                        backgroundColor: alpha(theme.palette.text.primary, theme.palette.action.hoverOpacity)
                    }
                })}
                style={{ margin:'0px 4px' }}
                endIcon={<AddBox />}
                variant='outlined'
                onClick={() => setAddUserDialogIsOpen(true)}
                aria-label='add member'>
                    Add
            </Button>
            <AddUserDialog
                isOpen={addUserDialogIsOpen}
                setIsOpen={setAddUserDialogIsOpen}
                excludeUserIds={currentMembers}
                apply={applyNewMembers}
            />
        </>
}
    
type UserListProps = {
    roleMembers: ApiCoreUser[]
    setRoleMembers: (newMembers: ApiCoreUser[]) => void
    editMode: boolean
}

const UsersList = ({ roleMembers, setRoleMembers, editMode }: UserListProps) => {
    const [ selectedMembers, setSelectedMembers ] = useState<number[]>([]);

    const selectAll = (checked: boolean) => {
        if (checked) {
            setSelectedMembers(roleMembers.map(m => m.coreUserId));
        } else {
            setSelectedMembers([]);
        }
    }

    const onMemberCheck = (userId: number, checked: boolean) => {
        if (checked) {
            setSelectedMembers([ ...selectedMembers, userId ]);
        } else {
            setSelectedMembers(selectedMembers.filter(m => m !== userId));
        }
    }

    const removeSelectedMembers = () => {
        setRoleMembers(roleMembers.filter(m => !selectedMembers.includes(m.coreUserId)));
        setSelectedMembers([]);
    }

    return <Card style={{ marginBottom:10, flexGrow:0.5, overflow:'auto', minHeight:'250px' }}>
        <MuiTable size='small' stickyHeader={true}>
            <TableHead>
                <TableRow>
                    { !editMode ? null : <TableCell padding='checkbox'>
                        <Checkbox color='secondary' disabled={!editMode} checked={roleMembers.length > 0 && selectedMembers.length === roleMembers.length} onChange={event => selectAll(event.target.checked)} />
                    </TableCell> }
                    <TableCell>Role Member</TableCell>
                    <TableCell>
                        <Stack direction='row-reverse' sx={{ height:'37px' }}>
                            <UsersListControls
                                editMode={editMode}
                                selectedUsers={selectedMembers}
                                currentMembers={roleMembers.map(rm => rm.coreUserId)}
                                removeSelectedUsers={removeSelectedMembers}
                                applyNewMembers={newMembers => setRoleMembers([ ...roleMembers, ...newMembers ])}
                            />
                        </Stack>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {roleMembers.map(m =>
                    <TableRow key={m.coreUserId}>
                        { !editMode ? null : <TableCell padding='checkbox'>
                            <Checkbox
                                color='secondary'
                                disabled={!editMode}
                                checked={selectedMembers.includes(m.coreUserId)}
                                onChange={event => onMemberCheck(m.coreUserId, event.target.checked)}
                            />
                        </TableCell>}
                        <TableCell colSpan={2}>{[m.firstName, m.surname].join(' ')}</TableCell>
                    </TableRow>)}
            </TableBody>
        </MuiTable>
    </Card>
};

interface PermissionsListProps {
    editMode: boolean
    selectedPermissionIds: number[],
    setSelectedPermissionIds: (permissionIds: number[]) => void
}

const PermissionsList = ({ editMode, selectedPermissionIds, setSelectedPermissionIds }: PermissionsListProps) => {
    const { getAccessTokenSilently } = useAuth0();
    const [ allPermissions, setAllPermissions ] = useState<Permission[]>([]);
    
    useEffect(() => {
        let cancelled = false;
        let cancellablePromise: CancellablePromise | null = null;

        const getPermissions = async () => {
            const token = await getAccessTokenSilently();
            if (cancelled) {
                return;
            }

            cancellablePromise = callDciApiCancellable('{allPermissions(order:{displayOrder:ASC}){permissionId,description,enabled}}', token)
            cancellablePromise.promise
            .then(body => {
                if (!body.errors) {
                    const newPermissions = body.data.allPermissions as Permission[];
                    setAllPermissions(newPermissions.map(m => ({ permissionId: m.permissionId!, enabled: m.enabled!, description: m.description! })));
                }
            });
        }

        getPermissions();
        return () => {
            cancelled = true;
            cancellablePromise?.abortController.abort();
        }
    }, []);

    const onPermissionCheck = (permissionId: number, checked: boolean) => {
        if (checked === true) {
            setSelectedPermissionIds([ ...selectedPermissionIds, permissionId ]);
        }
        else {
            setSelectedPermissionIds(selectedPermissionIds.filter(p => p !== permissionId));
        }
    };

    const selectAll = (checked: boolean) => {
        if (checked === true) {
            setSelectedPermissionIds(allPermissions.map(p => p.permissionId));
        }
        else {
            setSelectedPermissionIds([]);
        }
    };

    return <Card style={{ marginBottom:10, flexGrow:0.5, overflow:'auto' }}>
        <MuiTable size='small' stickyHeader={true}>
            <TableHead>
                <TableRow>
                    <TableCell padding='checkbox'><Checkbox color='secondary' disabled={!editMode} checked={selectedPermissionIds.length === allPermissions.length} onChange={event => selectAll(event.target.checked)} /></TableCell>
                    <TableCell>Permission</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {allPermissions.map(m => m.enabled === true &&
                    <TableRow key={m.permissionId}>
                        <TableCell padding='checkbox'><Checkbox color='secondary' disabled={!editMode} checked={selectedPermissionIds.includes(m.permissionId)} onChange={event => onPermissionCheck(m.permissionId, event.target.checked)} /></TableCell>
                        <TableCell>{m.description}</TableCell>
                    </TableRow>)}
            </TableBody>
        </MuiTable>
    </Card>
}

const changesMade = (original: ApiRole, editing: ApiRole): boolean => {
    if (editing === null) {
        return false;
    }

    if (editing.description !== editing.description) {
        return true;
    }

    if (editing.enabled !== editing.enabled) {
        return true;
    }

    if (!isEqual(sortBy(editing.coreRolePermissions.map(rp => rp.permissionId)), sortBy(original.coreRolePermissions.map(rp => rp.permissionId)))) {
        return true;
    }

    if (!isEqual(sortBy(editing.coreUserRoles.map(ur => ur.coreUser.coreUserId)), sortBy(original.coreUserRoles.map(ur => ur.coreUser.coreUserId)))) {
        return true;        
    }

    return false;
};

const saveChanges = async (value: ApiRole) => {
    const token = await authClient.getTokenSilently();
    const response = await postDciApi(`mutation{editCoreRole(coreRoleId:${value.coreRoleId},description:"${value.description}",enabled:${value.enabled},permissionIds:[${value.coreRolePermissions.map(rp => rp.permissionId).join()}],userIds:[${value.coreUserRoles.map(ur => ur.coreUser.coreUserId).join()}]){coreRoleId description enabled coreRolePermissions{permissionId}coreUserRoles{coreUserId coreUser{coreUserId firstName surname}}}}`, token);

    if (!response.errors) {
        return { success: true, message: null }
    } else {
        return { success: false, message: response.errors[0].message as string }
    }
}

const deleteRole = async (roleId: number, description: string, navigate: NavigateFunction, enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey) => {
    if (!window.confirm(`Delete role named '${description}'?`)) {
        return;
    }

    const token = await authClient.getTokenSilently();
    const response = await postDciApiNoThrow(`mutation{deleteCoreRole(coreRoleId:${roleId})}`, token);
    if (!response.errors) {
        navigate(dciPaths.coreRoles.buildLink());
        enqueueSnackbar(`Core Role '${description}' deleted successfully`, { variant:'success' });
    } else {
        enqueueSnackbar(response.errors[0].message, { variant:'error' });
    }
}

interface RolePanelProps {
    role: ApiRole | null
    setRole: React.Dispatch<React.SetStateAction<ApiRole | null>>
    editingValue: ApiRole | null
    editMode: boolean
    activateEditMode: () => void
    cancelEdit: () => void,
    setEditingValue: React.Dispatch<React.SetStateAction<ApiRole | null>>
}

const RolePanel = ({ role, setRole, editingValue, editMode, activateEditMode, cancelEdit, setEditingValue }: RolePanelProps) => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const currentUser = useStore(appGlobalStore, s => s.currentUser);

    const canEdit = userHasPermission(currentUser, permissions.EDIT_CORE_ROLES);
    const canDelete = userHasPermission(currentUser, permissions.DELETE_CORE_ROLES);

    const saveOnClick = async () => {
        const result = await saveChanges(editingValue!);
        if (result.success) {
            setRole({
                ...editingValue!,
                coreRolePermissions: [ ...editingValue!.coreRolePermissions ],
                coreUserRoles: [ ...editingValue!.coreUserRoles ]
            });
            enqueueSnackbar('Saved successfully', { variant: 'success' });
            cancelEdit();
        } else {
            enqueueSnackbar(result.message, { variant: 'error' });
        }
    }

    return <Card style={{ marginBottom:10, minHeight:'150px' }}>
        { !editMode && role === null ? <Stack height='100%' alignItems='center' justifyContent='center'>Loading...</Stack> :
            <CardContent>
                <Box style={{ float:'right' }}>
                    { editMode ? 
                        <Stack direction='row'>
                            <Button
                                startIcon={<Save />}
                                disabled={role === null || !changesMade(role, editingValue!)}
                                onClick={saveOnClick}
                            >
                                Save
                            </Button>
                            <Button disabled={role === null} onClick={cancelEdit}>Cancel Edit</Button>
                        </Stack> :
                        <>
                            { !canDelete ? null : <Button onClick={() => deleteRole(role!.coreRoleId, role!.description, navigate, enqueueSnackbar)} startIcon={<Delete />}>Delete</Button> }
                            { !canEdit ? null : <Button onClick={activateEditMode} startIcon={<Edit />}>Edit</Button> }
                        </>
                    }
                </Box>
                { editMode ? null : <Typography style={{ display:'inline' }} variant='h5' paragraph>Core Role: {role?.description}</Typography> }
                { !editMode ? null :
                    <Box maxWidth={350}>
                        <TextField
                            variant='standard'
                            style={{ marginBottom:'12px' }}
                            margin="dense"
                            label='Description'
                            id='description'
                            type="text"
                            fullWidth
                            value={editingValue!.description}
                            onChange={(event) => setEditingValue({ ...editingValue!, description: event.target.value })}
                        />
                    </Box>
                }
                <Box>
                    <CheckboxField
                        id='enabled'
                        disabled={!editMode}
                        displayName='Enabled'
                        value={editMode ? editingValue!.enabled : role!.enabled}
                        onChange={event => setEditingValue({ ...editingValue!, enabled:event.target.checked })}
                    />
                </Box>
            </CardContent>
        }
    </Card>
}

type RoleViewProps = {
    id: number,
    setBreadcrumbs: React.Dispatch<React.SetStateAction<SegmentMap[]>>
};

const CoreRoleView: React.FC<RoleViewProps> = ({ id, setBreadcrumbs }) => {
    const { getAccessTokenSilently } = useAuth0();
    const [ role, setRole ] = useState<ApiRole | null>(null);
    const [ editMode, setEditMode ] = useState(false);
    const [ editingValue, setEditingValue ] = useState<ApiRole | null>(null);

    useEffect(() => {
        let cancelled = false;
        let cancellablePromise: CancellablePromise | null = null;
        const getData = async () => {
            const token = await getAccessTokenSilently();
            if (cancelled) {
                return;
            }

            cancellablePromise = callDciApiCancellable(`{coreRoles(where:{coreRoleId:{eq:${id}}}){nodes{coreRoleId description enabled coreRolePermissions{permissionId}coreUserRoles{coreUserId coreUser{coreUserId firstName surname}}}}}`, token);
            cancellablePromise.promise
            .then(body => {
                if (body.errors) {
                    alert(body.errors[0].message);
                } else {
                    const apiRole = body.data.coreRoles.nodes[0] as ApiRole
                    setRole(apiRole)
                    setEditingValue(null);
                    setBreadcrumbs(oldValue => [ ...oldValue ].map((m, i) => i === oldValue.length - 1 ? { text: `${apiRole.description}` } : m));
                }
            });
        };
        
        getData();
        return () => {
            cancelled = true;
            cancellablePromise?.abortController.abort();
        }
    }, [ id ]);

    const activateEditMode = () => {
        setEditMode(true);
        setEditingValue({
            ...role!,
            coreRolePermissions: [ ...role!.coreRolePermissions ],
            coreUserRoles: [ ...role!.coreUserRoles ]
        });
    }

    const cancelEdit = () => {
        setEditMode(false);
        setEditingValue(null);
    };

    return (
        <OrgContainer>
            <Stack sx={{ height:'100%' }}>
                <RolePanel
                    role={role}
                    setRole={setRole}
                    editingValue={editingValue}
                    editMode={editMode}
                    activateEditMode={activateEditMode}
                    cancelEdit={cancelEdit}
                    setEditingValue={setEditingValue}
                />
                <UsersList
                    editMode={editMode}
                    roleMembers={editMode ? editingValue!.coreUserRoles.map(ur => ur.coreUser) : role?.coreUserRoles.map(ur => ur.coreUser) ?? []}
                    setRoleMembers={newMembers => setEditingValue(old => ({ ...old!, coreUserRoles: newMembers.map(m => ({ coreUser: m })) }))}
                />
                <PermissionsList
                    editMode={editMode}
                    selectedPermissionIds={editingValue?.coreRolePermissions.map(p => p.permissionId) ?? role?.coreRolePermissions.map(p => p.permissionId) ?? []}
                    setSelectedPermissionIds={newPermissionIds => setEditingValue(old => ({ ...old!, coreRolePermissions: newPermissionIds.map(p => ({ permissionId: p })) }))}
                />
            </Stack>
        </OrgContainer>
    )
};

export { CoreRoleView };