import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { 
    Avatar,
    List, 
    ListItem,
    ListItemAvatar,
    ListItemText,
    Paper,
    Divider,
    ListSubheader,
    Box,
    styled
} from '@mui/material';
import {
    AccountTree,
    Assignment,
    Business,
    Engineering,
    Group,
    Person,
    Rule,
    Schema,
    Settings,
    TableView,
    Work
} from '@mui/icons-material';
import dciPaths, { DciRoute } from '../../utils/dciPaths';
import { PermissionedComponent } from '../PermissionedComponent';
import { permissions } from '../../utils/dciConstants';
import { CompactMenu, CompactMenuItem } from '../Layout/CompactMenu';
import userHasPermission from '../../utils/userHasPermission';
import { appGlobalStore } from '../../AppGlobalStore';
import { useStore } from 'zustand';

const OrgMenu = styled(Paper)(({ theme }) =>`
    margin-right:20px;
    max-width:250px;
    min-width:250px;
    margin-bottom:${theme.spacing(2)};
    & .Mui-selected .MuiAvatar-root {
        background-color: ${theme.palette.secondary.main};
    }
`);

type OrgContainerProps = {
    children?: React.ReactNode
}

interface CoreAdminMenuItemProps {
    caption: string
    route: DciRoute
    permissionId: number
    icon?: JSX.Element
}

const CoreAdminMenuItem = ({ caption, route, permissionId, icon }: CoreAdminMenuItemProps) => {
    const currentUser = useStore(appGlobalStore, s => s.currentUser);
    const pathName = useLocation().pathname;

    return <CompactMenuItem
        caption={caption}
        href={route.buildLink()}
        shouldDisplay={userHasPermission(currentUser, permissionId)}
        selected={typeof route.regex === 'undefined' ? pathName.startsWith(route.buildLink()) : route.regex.test(pathName)}
        icon={icon}
    />
}

const OrgContainer = ({ children }: OrgContainerProps) => {
    const pathName = useLocation().pathname;
    const navigate = useNavigate();

    return (
        <Box height={'100%'} display='flex'>
            <div>
                <OrgMenu>
                    <List subheader={<ListSubheader component="div" id="nested-list-subheader">Configuration</ListSubheader>}>
                        <ListItem selected={['/configuration/organisation','/configuration/organisation/edit'].indexOf(pathName) !== -1} button onClick={() => navigate(dciPaths.organisation.buildLink())}>
                            <ListItemAvatar>
                                <Avatar><Business /></Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Organisation" />
                        </ListItem>
                        <PermissionedComponent permissionId={[permissions.VIEW_SYSTEM_SETTINGS, permissions.EDIT_SYSTEM_SETTINGS]}>
                            <ListItem selected={pathName === dciPaths.settings.buildLink()} button onClick={() => navigate('/configuration/orgconfig')}>
                                <ListItemAvatar>
                                    <Avatar><Settings /></Avatar>
                                </ListItemAvatar>
                                <ListItemText primary='Settings' />
                            </ListItem>
                        </PermissionedComponent>
                        <PermissionedComponent permissionId={[permissions.VIEW_SYSTEM_SETTINGS, permissions.EDIT_SYSTEM_SETTINGS]}>
                            <ListItem selected={pathName === dciPaths.orgConstants.buildLink()} button onClick={() => navigate('/configuration/orgconstants')}>
                                <ListItemAvatar>
                                    <Avatar><Settings /></Avatar>
                                </ListItemAvatar>
                                <ListItemText primary='Constants' />
                            </ListItem>
                        </PermissionedComponent>
                        <PermissionedComponent permissionId={[permissions.VIEW_SYSTEM_SETTINGS, permissions.EDIT_SYSTEM_SETTINGS]}>
                            <ListItem selected={pathName === dciPaths.orgValueLists.buildLink()} button onClick={() => navigate('/configuration/orgvaluelists')}>
                                <ListItemAvatar>
                                    <Avatar><Settings /></Avatar>
                                </ListItemAvatar>
                                <ListItemText primary='Value Lists' />
                            </ListItem>
                        </PermissionedComponent>
                        <Divider />
                        <PermissionedComponent permissionId={permissions.VIEW_LIST_OF_TEAMS}>
                            <ListItem selected={pathName.startsWith('/configuration/team')} button onClick={() => navigate(dciPaths.teams.buildLink())}>
                                <ListItemAvatar>
                                    <Avatar><Group /></Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="Teams" />
                            </ListItem>
                        </PermissionedComponent>
                        <PermissionedComponent permissionId={permissions.VIEW_LIST_OF_USERS}>
                            <ListItem selected={pathName.startsWith('/configuration/user')} button onClick={() => navigate(dciPaths.users.buildLink())}>
                                <ListItemAvatar>
                                    <Avatar><Person /></Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="Users" />
                            </ListItem>
                        </PermissionedComponent>
                        <PermissionedComponent permissionId={permissions.VIEW_LIST_OF_ROLES}>
                            <ListItem selected={pathName.startsWith('/configuration/role')} button onClick={() => navigate(dciPaths.roles.buildLink())}>
                                <ListItemAvatar>
                                    <Avatar><Work /></Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="Roles" />
                            </ListItem>
                        </PermissionedComponent>
                        <ListItem selected={pathName.startsWith('/configuration/workqueue')} button onClick={() => navigate(dciPaths.workQueues.buildLink())}>
                            <ListItemAvatar>
                                <Avatar><Assignment /></Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Work Queues" />
                        </ListItem>
                        <Divider />
                        <PermissionedComponent permissionId={permissions.VIEW_LIST_OF_RULES}>
                            <ListItem selected={pathName.startsWith('/configuration/rulegroup')} button onClick={() => navigate(dciPaths.ruleGroups.buildLink())}>
                                <ListItemAvatar>
                                    <Avatar><AccountTree /></Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="Rule Groups" />
                            </ListItem>
                        </PermissionedComponent>
                        <PermissionedComponent permissionId={permissions.VIEW_LIST_OF_RULES}>
                            <ListItem selected={/^\/configuration\/rules|^\/configuration\/rule\//.test(pathName)} button onClick={() => navigate(dciPaths.rules.buildLink())}>
                                <ListItemAvatar>
                                    <Avatar><AccountTree /></Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="Rules" />
                            </ListItem>
                        </PermissionedComponent>
                    </List>
                </OrgMenu>
                <PermissionedComponent permissionId={[ permissions.VIEW_EXTRACT_TABLES, permissions.VIEW_CORE_REPORT_TESTS, permissions.VIEW_ORGANISATION_SCHEMAS ]}>
                    <OrgMenu>
                        <CompactMenu>
                            <CompactMenuItem caption='Core Admin' />
                            <CoreAdminMenuItem caption='Tables' icon={<TableView />} route={dciPaths.coreTables} permissionId={permissions.VIEW_EXTRACT_TABLES} />
                            <CoreAdminMenuItem caption='Rules' icon={<Rule />} route={dciPaths.coreRulesList} permissionId={permissions.VIEW_CORE_REPORT_TESTS} />
                            <CoreAdminMenuItem caption='Schema' icon={<Schema />} route={dciPaths.coreSchema} permissionId={permissions.VIEW_ORGANISATION_SCHEMAS} />
                            <CoreAdminMenuItem caption='Core Roles' icon={<Engineering />} route={dciPaths.coreRoles} permissionId={permissions.VIEW_CORE_ROLES} />
                            <CoreAdminMenuItem caption='Work Item Attribute Maps' icon={<AccountTree />} route={dciPaths.workItemAttributeMaps} permissionId={permissions.VIEW_WORK_ITEM_ATTRIBUTE_MAPS} />
                        </CompactMenu>
                    </OrgMenu>
                </PermissionedComponent>
            </div>
            <div style={{ flex:1 }}>
                {children}
            </div>
        </Box>
    )
};

export default OrgContainer;