import { createTableStore, TableStoreContext } from "../Table/Stores/TableStore";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { TableContainer } from "../Table/TableContainer";
import { Table } from "../Table/Table";
import dciPaths from "../../utils/dciPaths";
import { TableColumn } from "../Filtering/types";
import { TableCell } from "@mui/material";
import { Check, Clear } from "@mui/icons-material";
import OrgContainer from "../Organisation/OrgContainer";

interface WorkItemAttributeMap {
    workItemAttributeMapId: number
    description: string
    enabled: boolean
}

const columns: TableColumn[] = [
    {
        displayName: "Description",
        selector: "description"
    },
    {
        displayName: "Enabled",
        selector: "enabled",
        render: row => (<TableCell key={'enabled'} style={{ textAlign: 'center' }} padding={'checkbox'}>{row.enabled ? <Check /> : <Clear />}</TableCell>)
    }
];

const createStore = () => createTableStore({
    graphQLQueryName: 'workItemAttributeMaps',
    graphQLQueryColumns: '{workItemAttributeMapId,description,enabled}',
    uniqueSortColumn: 'workItemAttributeMapId',
    idFromRow: (row: WorkItemAttributeMap) => row.workItemAttributeMapId
},
{
    paged: true
});

const WorkItemAttributeMapsList = () => {
    const navigate = useNavigate();
    const [ store ] = useState(() => createStore())

    return (
        <OrgContainer>
            <TableStoreContext.Provider value={store}>
                <TableContainer
                    title='Work Item Attribute Maps'
                    paged
                    style={{ height:'100%', width:'100%' }}
                    enableExport
                >
                    <Table
                        uniqueSortColumn='workItemAttributeMapId'
                        columns={columns}
                        idFromValue={(row: WorkItemAttributeMap) => row.workItemAttributeMapId}
                        onRowClick={(row: WorkItemAttributeMap) => navigate(dciPaths.workItemAttributeMap.buildLink(row.workItemAttributeMapId))}
                    />
                </TableContainer>
            </TableStoreContext.Provider>
        </OrgContainer>
    )
};

export { WorkItemAttributeMapsList }