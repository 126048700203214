import { useState } from 'react';
import { AddBox } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { Check, Clear } from '@mui/icons-material';
import { 
    Button,
    TableCell, 
    alpha
} from '@mui/material';

import dciPaths from '../../utils/dciPaths';
import { TableStoreContext, createTableStore, useTableState } from '../Table/Stores/TableStore';
import { TableContainer } from '../Table/TableContainer';
import { Table } from '../Table/Table';
import { TableColumn } from '../Filtering/types';
import OrgContainer from '../Organisation/OrgContainer';
import userHasPermission from '../../utils/userHasPermission';
import { useStore } from 'zustand';
import { appGlobalStore } from '../../AppGlobalStore';
import { permissions } from '../../utils/dciConstants';
import { AddDialog } from '../AddDialog';
import { useAuth0 } from '@auth0/auth0-react';
import { postDciApiNoThrow } from '../../utils/callDciApi';
import { useSnackbar } from 'notistack';

type CoreRole = {
    coreRoleId: number,
    description: string,
    enabled: boolean
}

const columns: TableColumn[] = [
    {
        displayName: "Description",
        selector: "description"
    },
    {
        displayName: "Enabled",
        selector: "enabled",
        render: row => (<TableCell key={'enabled'} style={{ textAlign: 'center' }} padding={'checkbox'}>{row.enabled ? <Check /> : <Clear />}</TableCell>)
    }
];

const createStore = () => createTableStore({
        graphQLQueryName: 'coreRoles',
        graphQLQueryColumns: '{coreRoleId,description,enabled}',
        uniqueSortColumn: 'coreRoleId',
        idFromRow: (row: CoreRole) => row.coreRoleId
    },
    {
        paged: true
    });

const Controls = () => {
    const [ addRoleDialogIsOpen, setAddRoleDialogIsOpen ] = useState(false);

    const updateData = useTableState(s => s.updateRowData);
    const currentUser = useStore(appGlobalStore, s => s.currentUser);

    const { getAccessTokenSilently } = useAuth0();
    const { enqueueSnackbar } = useSnackbar();

    if (!userHasPermission(currentUser, permissions.CREATE_CORE_ROLES)) {
        return null;
    }
    
    const closeDialog = (refresh: boolean) => {
        setAddRoleDialogIsOpen(false);
        if (refresh) {
            updateData(false);
        }
    }

    const onSave = async (name: string) => {
        const token = await getAccessTokenSilently();
        const response = await postDciApiNoThrow(`mutation{createCoreRole(name:"${name}")}`, token);
        if (response.errors) {
            enqueueSnackbar(response.errors[0].message, { variant:'error' });
            return false;
        }

        return true;
    }
    
    return <>
        <Button
            sx={theme => ({ 
                color:theme.palette.text.primary,
                borderColor:theme.palette.divider,
                '&:hover': {
                    borderColor: theme.palette.action.disabledBackground,
                    backgroundColor: alpha(theme.palette.text.primary, theme.palette.action.hoverOpacity)
                }
            })}
            style={{ margin:'0px 4px' }}
            endIcon={<AddBox />}
            variant='outlined'
            onClick={() => setAddRoleDialogIsOpen(true)}
            aria-label='add role'>
                Add
        </Button>
        <AddDialog
            close={closeDialog}
            isOpen={addRoleDialogIsOpen}
            title='Create Core Role'
            fieldLabel='Choose a name for the new Core Role'
            onSave={onSave}
        />
    </>;
}

export default function CoreRoles() {
    const navigate = useNavigate();
    const [ store ] = useState(() => createStore())

    return (
        <OrgContainer>
            <TableStoreContext.Provider value={store}>
                <TableContainer
                    title='Core Roles'
                    paged
                    style={{ height:'100%', width:'100%' }}
                    enableExport
                    toolbarButtons={<Controls />}
                >
                    <Table
                        uniqueSortColumn='coreRoleId'
                        columns={columns}
                        idFromValue={(row: CoreRole) => row.coreRoleId}
                        onRowClick={(row: CoreRole) => navigate(dciPaths.coreRole.buildLink(row.coreRoleId))}
                    />
                </TableContainer>
            </TableStoreContext.Provider>
        </OrgContainer>
    )
};