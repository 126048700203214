import { useState } from 'react';
import { Box, CircularProgress, LinearProgress, useTheme } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from '@mui/material';

interface AddDialogProps {
    isOpen: boolean,
    close: (saved: boolean) => void
    onSave: (name: string) => Promise<boolean>
    fieldLabel?: string
    title: string
    addButtonLabel?: string
}

const AddDialog = ({ isOpen, close, onSave, fieldLabel, title, addButtonLabel = 'Create' }: AddDialogProps) => {
    const [ saving, setSaving ] = useState(false);
    const [ name, setName ] = useState('');
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const saveOnClick = async () => {
        setSaving(true);
        try {
            if (await onSave(name)) {
                close(true);
                setName('');
            }
        } finally {
            setSaving(false);
        }
    }

    const cancelOnClick = () => {
        close(false);
        setName('');
    }

    return (
        <Dialog open={isOpen} fullScreen={fullScreen} maxWidth={'sm'} fullWidth aria-labelledby='form-dialog-title'>
            <DialogTitle id='form-dialog-title'>{title}</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin='dense'
                    id='name'
                    label={fieldLabel}
                    type='text'
                    fullWidth
                    variant={'outlined'}
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={cancelOnClick}>Cancel</Button>
                {/* <Button disabled={name === ''} onClick={saveOnClick}>{ saving ? <Circular />  : addButtonLabel}</Button> */}
                <Box sx={{ m: 1, position: 'relative' }}>
                    <Button
                        disabled={name === '' || saving}
                        onClick={saveOnClick}
                    >
                        {addButtonLabel}
                    </Button>
                        { saving && (
                            <CircularProgress
                                size={24}
                                sx={theme => ({
                                    color: theme.palette.secondary.main,
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                })}
                            />
                        )}
                </Box>
            </DialogActions>
        </Dialog>
    );
};

export { AddDialog }